import { useState } from "react";

const UploadSTLModal = ({
  files,
  titles,
  orderNumbers,
  progress,
  uploading,
  success,
  onClose,
  onUpload,
}) => {
  const [editedTitles, setEditedTitles] = useState(titles);
  const [editedOrderNumbers, setEditedOrderNumbers] = useState(orderNumbers);

  const handleTitleChange = (index, value) => {
    const newTitles = [...editedTitles];
    newTitles[index] = value;
    setEditedTitles(newTitles);
  };

  const handleOrderNumberChange = (index, value) => {
    const newOrderNumbers = [...editedOrderNumbers];
    newOrderNumbers[index] = value;
    setEditedOrderNumbers(newOrderNumbers);
  };

  const handleIncrement = (index) => {
    const newValue = parseInt(editedOrderNumbers[index]) + 1;
    handleOrderNumberChange(index, newValue.toString());
  };

  const handleDecrement = (index) => {
    const newValue = Math.max(0, parseInt(editedOrderNumbers[index]) - 1);
    handleOrderNumberChange(index, newValue.toString());
  };

  const handleUpload = () => {
    onUpload(editedTitles, editedOrderNumbers);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded shadow-lg w-1/2 max-h-screen overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Upload STL Files</h2>
        <div className="max-h-[60vh] overflow-y-auto">
          <div className="flex justify-between mb-2">
            <div className="font-semibold text-gray-600">Title</div>
            <div className="font-semibold text-gray-600 text-center">
              Render Order
            </div>
          </div>
          {Array.from(files).map((file, index) => (
            <div key={index} className="mb-4">
              <p className="font-medium text-gray-700 mb-2">
                {file.name.split(".").slice(0, -1).join(".")}
              </p>
              <div className="flex items-center space-x-4">
                <input
                  type="text"
                  placeholder="Title"
                  value={editedTitles[index]}
                  onChange={(e) => handleTitleChange(index, e.target.value)}
                  className="border border-gray-300 ml-1 p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
                <div className="relative w-16">
                  <input
                    type="text"
                    value={editedOrderNumbers[index]}
                    onChange={(e) =>
                      handleOrderNumberChange(index, e.target.value)
                    }
                    className="border border-gray-300 p-2 rounded text-center w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                    disabled
                  />
                  <div className="absolute inset-y-0 right-0 flex flex-col items-center justify-center">
                    <button
                      onClick={() => handleIncrement(index)}
                      className="bg-gray-400 text-white text-xs h-5 w-full rounded-t focus:outline-none hover:bg-gray-500 transition"
                      disabled={uploading}
                    >
                      ▲
                    </button>
                    <button
                      onClick={() => handleDecrement(index)}
                      className="bg-gray-400 text-white text-xs h-5 w-full rounded-b focus:outline-none hover:bg-gray-500 transition"
                      disabled={uploading}
                    >
                      ▼
                    </button>
                  </div>
                </div>
              </div>
              {progress[index] != null && (
                <div className="mt-2">
                  <p className="text-sm text-gray-600">
                    Upload progress: {progress[index].toFixed(2)}%
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${progress[index]}%` }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-end space-x-4 mt-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-300 hover:bg-gray-600 transition"
            disabled={uploading}
          >
            Cancel
          </button>
          <button
            onClick={handleUpload}
            className="bg-blue-500 text-white px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-300 hover:bg-blue-600 transition"
            disabled={uploading}
          >
            Upload
          </button>
        </div>
        {uploading && success && (
          <div className="mt-4 text-green-500 text-center">
            Upload Successful!
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadSTLModal;
