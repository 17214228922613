import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/userRedux";
import { database } from "../firebase";
import { ref, onValue, off, remove } from "firebase/database";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { TrashIcon } from "@heroicons/react/solid";

const CaseListPageView = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const userRef = useRef(null);
  const [caseList, setCaseList] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [showDeleteButtons, setShowDeleteButtons] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseToDelete, setCaseToDelete] = useState(null);

  useEffect(() => {
    if (user.isSignedIn) {
      userRef.current = ref(database, "users/" + user.currentUid);
      onValue(userRef.current, (snapshot) => {
        const data = snapshot.val();
        dispatch(setCurrentUser(data));
      });

      // Cleanup on unmount
      return () => {
        if (userRef.current) {
          off(userRef.current);
        }
      };
    }
  }, [dispatch, user.currentUid, user.isSignedIn]);

  useEffect(() => {
    if (user.currentUser && user.currentUser.cases) {
      const casesData = user.currentUser.cases;
      const caseArray = Object.keys(casesData).map((key) => ({
        id: key,
        ...casesData[key],
        dueDate: new Date(casesData[key].dueDate).toLocaleDateString(),
        creationDate: new Date(
          casesData[key].creationDate
        ).toLocaleDateString(),
        expirationDate: new Date(
          casesData[key].expirationDate
        ).toLocaleDateString(),
      }));
      setCaseList(caseArray);
    }
  }, [user.currentUser]);

  const handleDelete = async () => {
    if (!user.currentUid || !caseToDelete) return;

    try {
      const caseRef = ref(
        database,
        `users/${user.currentUid}/cases/${caseToDelete}`
      );
      await remove(caseRef);
      setCaseList((prev) =>
        prev.filter((caseItem) => caseItem.id !== caseToDelete)
      );
      setIsModalOpen(false);
      setShowDeleteButtons(false);
    } catch (err) {
      console.error("Error deleting case from Firebase Database", err);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return "";
  };

  const sortedCaseList = [...caseList]
    .filter(
      (caseItem) =>
        caseItem.title &&
        caseItem.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (!a[sortConfig.key] || !b[sortConfig.key]) return 0;
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

  const openModal = (caseId) => {
    setCaseToDelete(caseId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCaseToDelete(null);
  };

  return (
    <div className="p-8">
      <h2 className="text-lg font-semibold mb-4">Case List</h2>
      <div className="flex justify-between mb-4">
        <div className="flex items-center space-x-4">
          <input
            type="text"
            placeholder="Search..."
            className="px-4 py-2 border border-gray-300 rounded"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            onClick={() => navigate("/addcasepage")}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Add Case
          </button>
          <button
            onClick={() => setShowDeleteButtons(!showDeleteButtons)}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
      <CaseTable
        caseList={sortedCaseList}
        requestSort={requestSort}
        getSortDirection={getSortDirection}
        showDeleteButtons={showDeleteButtons}
        openModal={openModal}
      />
      <DeleteConfirmationModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};

const CaseTable = ({
  caseList,
  requestSort,
  getSortDirection,
  showDeleteButtons,
  openModal,
}) => (
  <table className="w-full border-collapse">
    <thead>
      <tr className="bg-gray-200">
        {[
          { key: "id", label: "Case ID" },
          { key: "title", label: "Title" },
          { key: "requester", label: "Requester" },
          { key: "anatomy", label: "Anatomy" },
          { key: "dueDate", label: "Due Date" },
          { key: "shared", label: "Shared Users" },
          { key: "creator", label: "Creator" },
          { key: "creationDate", label: "Creation Date" },
          { key: "expirationDate", label: "Expiration Date" },
          { key: "status", label: "Status" },
        ].map(({ key, label }) => (
          <th
            key={key}
            className="p-2 text-center border-l-2 border-l-white cursor-pointer"
            onClick={() => requestSort(key)}
          >
            {label} {getSortDirection(key)}
          </th>
        ))}
        {showDeleteButtons && (
          <th className="p-2 text-center border-l-2 border-l-white">Actions</th>
        )}
      </tr>
    </thead>
    <tbody>
      {caseList.map((caseItem, index) => (
        <tr
          key={index}
          className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
        >
          <td className="p-2 text-center">
            <Link to={`/case/${caseItem.id}`}>{caseItem.id}</Link>
          </td>
          <td className="p-2 text-center">{caseItem.title}</td>
          <td className="p-2 text-center">{caseItem.requester}</td>
          <td className="p-2 text-center">{caseItem.anatomy}</td>
          <td className="p-2 text-center">{caseItem.dueDate}</td>
          <td className="p-2 text-center">{caseItem.shared}</td>
          <td className="p-2 text-center">{caseItem.creator}</td>
          <td className="p-2 text-center">{caseItem.creationDate}</td>
          <td className="p-2 text-center">{caseItem.expirationDate}</td>
          <td
            className={`
              p-2 text-center 
              ${caseItem.status === "IN PROGRESS" ? "bg-yellow-200" : ""}
              ${caseItem.status === "READY" ? "bg-green-200" : ""}
              ${caseItem.status === "UPLOAD DICOM" ? "bg-orange-200" : ""}
            `}
          >
            {caseItem.status}
          </td>
          {showDeleteButtons && (
            <td className="p-2 text-center">
              <button
                onClick={() => openModal(caseItem.id)}
                className="hover:text-red-600"
              >
                <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-600" />
              </button>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
);

const DeleteConfirmationModal = ({ isOpen, closeModal, handleDelete }) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Confirm Delete
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this case? This action cannot
                  be undone.
                </p>
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  className="mr-2 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default CaseListPageView;
