const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 py-6 mt-auto">
      <div className="container mx-auto text-center">
        <p className="text-slate-50 text-lg">
          Copyright © 2024 Wellztech. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
