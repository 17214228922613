import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ref as dbRef, set } from "firebase/database";
import { database, storage } from "../firebase";
import {
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
  ref as storageRef,
} from "firebase/storage";
import UploadSTLModal from "./modals/UploadSTLModal";
import UploadDICOMModal from "./modals/UploadDICOMModal";
import { updateModelInfos } from "../redux/userRedux";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const CasesPageView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const { id } = useParams();
  const [caseInfo, setCaseInfo] = useState([]);
  const [modelInfos, setModelInfos] = useState([]);
  const [showSTLModal, setShowSTLModal] = useState(false);
  const [showDICOMModal, setShowDICOMModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [progress, setProgress] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [orderNumbers, setOrderNumbers] = useState([]);

  useEffect(() => {
    if (user) {
      const specificCase = Object.values(user.cases).find(
        (caseItem) => caseItem.id === id
      );
      if (specificCase) {
        setCaseInfo(specificCase);
        setModelInfos(Object.values(specificCase.items?.modelInfos || []));
      }
    }
  }, [id, user]);

  const handleClick = (modelId) => {
    navigate(`/model/${modelId}`);
  };

  const handleDelete = async (index) => {
    const modelInfosRef = dbRef(
      database,
      `users/${user.id}/cases/${id}/items/modelInfos`
    );
    const updatedModelInfos = modelInfos.filter((_, i) => i !== index);
    const fileToDelete = modelInfos[index];

    await set(modelInfosRef, updatedModelInfos);
    setModelInfos(updatedModelInfos);
    dispatch(updateModelInfos({ caseId: id, modelInfos: updatedModelInfos }));

    if (fileToDelete.storageName) {
      const fileRef = storageRef(
        storage,
        `${id}/stlFiles/${fileToDelete.storageName}`
      );
      await deleteObject(fileRef);
    }
  };

  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSTLFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setTitles(
      selectedFiles.map((file) => file.name.split(".").slice(0, -1).join("."))
    );
    setOrderNumbers(selectedFiles.map(() => "0"));
    setShowSTLModal(true);
  };

  const handleDICOMFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFiles([selectedFile]);
      setShowDICOMModal(true);
    }
  };

  const handleSTLUpload = async (titles, orderNumbers) => {
    setUploading(true);

    const newModelInfos = [...modelInfos];
    const uploadPromises = files.map((file, index) => {
      return new Promise((resolve, reject) => {
        const uniqueName = `${titles[index]}_${Date.now()}.stl`;
        const uploadRef = storageRef(storage, `${id}/stlFiles/${uniqueName}`);
        const uploadTask = uploadBytesResumable(uploadRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[index] = progress;
              return newProgress;
            });
          },
          (error) => {
            console.error("Upload failed:", error);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const newModelInfo = {
                name: titles[index] || file.name,
                url: downloadURL,
                uploadedBy: `${user.firstName} ${user.lastName}`,
                uploadDate: new Date().toDateString(),
                storageName: uniqueName,
                orderNumber: orderNumbers[index],
              };
              newModelInfos.push(newModelInfo);
              resolve(newModelInfo);
            });
          }
        );
      });
    });

    await Promise.all(uploadPromises);

    const modelInfosRef = dbRef(
      database,
      `users/${user.id}/cases/${id}/items/modelInfos`
    );
    await set(modelInfosRef, newModelInfos);

    setModelInfos(newModelInfos);

    dispatch(
      updateModelInfos({
        caseId: id,
        modelInfos: newModelInfos,
      })
    );

    setUploading(false);
    setSuccess(true);
    setSuccess(false);
    setShowSTLModal(false);
    setFiles([]);
    setTitles([]);
    setProgress([]);
  };

  const handleDICOMUpload = async () => {
    setUploading(true);

    const file = files[0];
    if (file) {
      const uniqueName = `${file.name}_${Date.now()}`;
      const uploadRef = storageRef(storage, `${id}/dicomFiles/${uniqueName}`);
      const uploadTask = uploadBytesResumable(uploadRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress([progress]); // Tek bir dosyanın progress durumunu güncelle
        },
        (error) => {
          console.error("Upload failed:", error);
          setUploading(false);
        },
        async () => {
          setUploading(false);
          setSuccess(true);

          // Status güncellemesi
          const caseRef = dbRef(database, `users/${user.id}/cases/${id}`);
          await set(caseRef, {
            ...caseInfo,
            status: "IN PROGRESS",
          });

          setTimeout(() => {
            setSuccess(false);
            setShowDICOMModal(false);
            setFiles([]);
            setProgress([]);
          }, 1000);
        }
      );
    }
  };

  return (
    <div className="p-8">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="text-blue-400 px-4 py-2 rounded mb-4 transition-colors duration-300 hover:bg-blue-500"
      >
        <ArrowBackIosNewRoundedIcon />
      </button>
      {/* Case Information */}
      <div className="mb-8">
        <div className="flex justify-between items-start w-full">
          <div className="w-2/3">
            <h2 className="text-lg font-semibold mb-2">Case Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="border-r border-gray-400 pr-4">
                <p className="text-gray-600">ID: {caseInfo.id}</p>
                <p className="text-gray-600">Name: {caseInfo.title}</p>
                <p className="text-gray-600">Anatomy: {caseInfo.anatomy}</p>
              </div>
              <div>
                <p className="text-gray-600">
                  Creation Date: {caseInfo.creationDate}
                </p>
                <p className="text-gray-600">
                  Expiry Date: {caseInfo.expiryDate}
                </p>
                <p className="text-gray-600">Due Date: {caseInfo.dueDate}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2 w-1/3">
            {modelInfos.length > 0 && (
              <button
                onClick={() => handleClick(id)}
                className="bg-blue-500 text-white px-4 py-2 rounded w-60 text-center transition-colors duration-300 hover:bg-blue-600 hover:text-gray-100"
              >
                Open in 3D Viewer
              </button>
            )}
            <label className="bg-green-500 text-white px-4 py-2 rounded cursor-pointer w-60 text-center transition-colors duration-300 hover:bg-green-600 hover:text-gray-100">
              Upload STL
              <input
                type="file"
                accept=".stl"
                multiple
                className="hidden"
                onChange={handleSTLFileSelect}
              />
            </label>
            <label className="bg-orange-500 text-white px-4 py-2 rounded cursor-pointer w-60 text-center transition-colors duration-300 hover:bg-orange-600 hover:text-gray-100">
              Upload DICOM (ZIP)
              <input
                type="file"
                accept=".zip, .rar"
                className="hidden"
                onChange={handleDICOMFileSelect}
              />
            </label>
          </div>
        </div>
      </div>

      {/* Model Files */}
      <div>
        <h2 className="text-lg font-semibold mb-2">Model Files</h2>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 text-center border-l-2 border-l-white">
                Name
              </th>
              <th className="p-2 text-center border-l-2 border-l-white">
                Uploaded By
              </th>
              <th className="p-2 text-center border-l-2 border-l-white">
                Upload Date
              </th>
              <th className="p-2 text-center border-l-2 border-l-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {modelInfos?.map((file, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
              >
                <td className="p-2 text-center">{file.name}</td>
                <td className="p-2 text-center">{file.uploadedBy}</td>
                <td className="p-2 text-center">{file.uploadDate}</td>
                <td className="p-2 text-center">
                  <button
                    className="px-2 py-1 rounded hover:text-blue-500"
                    onClick={() => handleDownload(file)}
                  >
                    <FileDownloadOutlinedIcon />
                  </button>
                  <button
                    className="px-2 py-1 rounded hover:text-red-500"
                    onClick={() => handleDelete(index)}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showSTLModal && (
        <UploadSTLModal
          files={files}
          titles={titles}
          orderNumbers={orderNumbers}
          progress={progress}
          uploading={uploading}
          success={success}
          onClose={() => setShowSTLModal(false)}
          onUpload={(newTitles, newOrderNumbers) => {
            setTitles(newTitles);
            setOrderNumbers(newOrderNumbers);
            handleSTLUpload(newTitles, newOrderNumbers);
          }}
        />
      )}
      {showDICOMModal && (
        <UploadDICOMModal
          progress={progress[0]}
          uploading={uploading}
          success={success}
          onClose={() => setShowDICOMModal(false)}
          onUpload={handleDICOMUpload}
        />
      )}
    </div>
  );
};

export default CasesPageView;
