import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DropDownMenu from "./DropDownMenu";

const Navbar = () => {
  const navigate = useNavigate();
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div className="relative" ref={menuRef}>
      <nav className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600">
        <div>
          <div className="flex items-center justify-between h-16 px-16">
            <div className="flex justify-center items-center space-x-2 text-slate-50 text-lg font-semibold">
              <span>WELLZTECH 3D MODEL HUB</span>
            </div>
            <div className="flex items-center space-x-4">
              {isSignedIn ? (
                <>
                  <button
                    className="flex justify-center items-center hover:text-sky-400 text-slate-200 shadow rounded-md text-sm font-medium cursor-pointer h-10"
                    onClick={() => setMenuOpen(!menuOpen)}
                  >
                    <AccountCircleIcon className="ml-2" />
                    <div className="px-3 py-2">
                      {currentUser?.firstName} {currentUser?.lastName}
                    </div>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="px-4 py-2 rounded-md text-sm font-medium shadow hover:text-sky-400 text-slate-200"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </button>
                  <button
                    className="px-4 py-2 rounded-md text-sm font-medium shadow hover:text-sky-400 text-slate-200"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      <DropDownMenu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </div>
  );
};

export default Navbar;
