import Navbar from "../components/Navbar";
import AddCasePageView from "../components/AddCasePageView";

const AddCasePage = () => {
    return (
      <>
        <Navbar />
        <AddCasePageView />
      </>
    );
  };

  export default AddCasePage;