import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth, database } from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { ref, set } from "firebase/database";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SignUpForm = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [department, setDepartment] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const formatName = (name) => {
    return name
      .split(" ")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(" ");
  };

  const handleChange = (setter, formatFunc) => (e) => {
    setter(formatFunc(e.target.value));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setError("Please fill in all required fields");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 6) {
      setError("Password should be at least 6 characters");
      return;
    }

    setError("");

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      ).catch((error) => {
        const errorMessage = error.message;

        const getErrorMessage = (errorMessage) => {
          if (errorMessage.includes("user-not-found")) {
            return "User not found!";
          }
          if (errorMessage.includes("wrong-password")) {
            return "You entered the wrong password!";
          }
          if (errorMessage.includes("network-request-failed")) {
            return "Please check your internet connection!";
          }
          if (errorMessage.includes("missing-email")) {
            return "Please enter your email address";
          }
          if (errorMessage.includes("invalid-email")) {
            return "Please enter a valid email address";
          } else {
            return "Something went wrong!";
          }
        };
        setError(getErrorMessage(errorMessage));
      });
      await sendEmailVerification(userCredential.user);
      const user = userCredential.user;

      const newUserKey = user.uid;
      const userRef = ref(database, `users/${newUserKey}`);

      const userData = {
        id: newUserKey,
        firstName,
        lastName,
        department,
        hospitalName,
        number,
        email,
      };

      await set(userRef, userData);

      navigate("/login");
    } catch (error) {
      const errorMessage = error.message;

      const getErrorMessage = (errorMessage) => {
        if (errorMessage.includes("user-not-found")) {
          return "User not found!";
        }
        if (errorMessage.includes("wrong-password")) {
          return "You entered the wrong password!";
        }
        if (errorMessage.includes("network-request-failed")) {
          return "Please check your internet connection!";
        }
        if (errorMessage.includes("missing-email")) {
          return "Please enter your email address";
        }
        if (errorMessage.includes("invalid-email")) {
          return "Please enter a valid email address";
        } else {
          return "Something went wrong!";
        }
      };
      setError(getErrorMessage(errorMessage));
    }
  };

  return (
    <div
      style={{ backgroundColor: "#f8faef" }}
      className="flex items-center justify-center h-screen"
    >
      <div className="flex-1 max-w-xl mx-auto">
        <div className="p-8 ml-16 rounded-xl shadow-md shadow-blue-100 bg-blue-100/50">
          <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-500 text-blue-500">
            Register
          </h2>
          <form className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-gray-500/75 text-sm font-semibold mb-1"
                >
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                  placeholder="Enter your first name"
                  required
                  value={firstName}
                  onChange={handleChange(setFirstName, formatName)}
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-gray-500/75 text-sm font-semibold mb-1"
                >
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                  placeholder="Enter your last name"
                  required
                  value={lastName}
                  onChange={handleChange(setLastName, formatName)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="department"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Department
              </label>
              <input
                id="department"
                type="text"
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter your department"
                required
                value={department}
                onChange={handleChange(
                  setDepartment,
                  formatName
                )}
              />
            </div>
            <div>
              <label
                htmlFor="hospitalName"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Hospital Name
              </label>
              <input
                id="hospitalName"
                type="text"
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter your hospital name"
                required
                value={hospitalName}
                onChange={handleChange(
                  setHospitalName,
                  formatName
                )}
              />
            </div>
            <div>
              <label
                htmlFor="number"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Phone Number
              </label>
              <PhoneInput
                value={number}
                onChange={setNumber}
                inputStyle={{ width: "100%" }}
                placeholder="select your country and enter your phone number"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="border-b-2 pb-6 border-blue-500">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-500/75 text-sm font-semibold mb-1"
              >
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <div className="py-2">
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-bold"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
            {error && <p className="text-red-600 mt-2">{error}</p>}
            <p className="mt-2 text-sm text-gray-500/75 text-center">
              Already have an account?
              <NavLink
                to="/login"
                className="ml-1 text-blue-500 hover:underline underline-offset-2"
              >
                Please Login
              </NavLink>
              .
            </p>
          </form>
        </div>
      </div>
      <div className="flex-1">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/d-viewer-de717.appspot.com/o/source%2Fhello.jpg?alt=media&token=1fa16b85-b32e-4711-89e4-0151e06797ba"
          alt="3d model"
          className="w-full h-screen object-cover"
        />
      </div>
    </div>
  );
};

export default SignUpForm;
