import Navbar from "../components/Navbar";
import ProfilePageView from "../components/ProfilePageView";

const ProfilePage = () => {
    return (
      <>
        <Navbar />
        <ProfilePageView />
      </>
    );
  };
  
  export default ProfilePage;