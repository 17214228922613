// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.c4om/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAeFIT-im01yB-q16jaw_8D1PWegsG7CoI",
  authDomain: "d-viewer-de717.firebaseapp.com",
  projectId: "d-viewer-de717",
  storageBucket: "d-viewer-de717.appspot.com",
  messagingSenderId: "179181786426",
  appId: "1:179181786426:web:ec7aab5e19250808216c03",
  measurementId: "G-6W5ERQ743P",
  databaseURL:
    "https://d-viewer-de717-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
export default app;
