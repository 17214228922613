import Navbar from "../components/Navbar";
import ThreeDModelView from "../components/ThreeDModelView";

const ThreeDModel = () => {
  return (
    <>
      <Navbar />
      <ThreeDModelView />
    </>
  );
};

export default ThreeDModel;
