import { useState, useEffect } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { CirclePicker, ChromePicker } from "react-color";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const ModelsSidebar = ({
  modelsRefs,
  onOpacityChange,
  onVisibilityChange,
  onColorChange,
}) => {
  const [visibility, setVisibility] = useState([]);
  const [opacity, setOpacity] = useState([]);
  const [colors, setColors] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState([]);
  const [colorPalette, setColorPalette] = useState([
    "#d3d3d3",
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
    "#000000",
    "#808080",
    "#FFA500",
  ]);
  const [showAddColorPicker, setShowAddColorPicker] = useState(false);
  const [newColor, setNewColor] = useState("");

  useEffect(() => {
    setVisibility(modelsRefs.map((model) => model.visible));
    setOpacity(modelsRefs.map((model) => model.material.opacity));
    setColors(modelsRefs.map((model) => model.material.color.getStyle()));
    setShowColorPicker(modelsRefs.map(() => false));
  }, [modelsRefs]);

  const handleVisibilityChange = (index) => {
    const model = modelsRefs[index];
    if (model) {
      model.visible = !model.visible;
      const newVisibility = [...visibility];
      newVisibility[index] = model.visible;
      onVisibilityChange(index, model.visible);
      setVisibility(newVisibility);
    }
  };

  const handleOpacityChange = (index, value) => {
    const model = modelsRefs[index];
    if (model) {
      const newOpacity = value;
      const opacityChange = [...opacity];
      opacityChange[index] = newOpacity;
      onOpacityChange(index, newOpacity);
      setOpacity(opacityChange);
    }
  };

  const handleColorChange = (color, index) => {
    const newColors = [...colors];
    newColors[index] = color.hex;
    setColors(newColors);
    onColorChange(index, color.hex);
  };

  const toggleColorPicker = (index) => {
    const newShowColorPicker = showColorPicker.map((show, i) =>
      i === index ? !show : false
    );
    setShowColorPicker(newShowColorPicker);
  };

  const handleNewColorChange = (color) => {
    setNewColor(color.hex);
  };

  const addNewColor = () => {
    setColorPalette([...colorPalette, newColor]);
    setShowAddColorPicker(false);
    setNewColor("");
  };

  return (
    <div className="absolute right-0 top-16 w-64 h-4/5 bg-gray-100 overflow-y-auto shadow-lg">
      <ul className="list-none m-0 p-0">
        {modelsRefs.map((modelRef, index) => (
          <li
            key={index}
            className="cursor-pointer p-4 hover:bg-blue-100 transition-colors duration-150 ease-in-out flex flex-col space-y-2 border-b border-gray-200"
          >
            <div className="flex justify-between space-x-4 w-full">
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <button
                    style={{ backgroundColor: colors[index] }}
                    className="w-6 h-6 rounded-full border-2 border-gray-300"
                    onClick={() => toggleColorPicker(index)}
                  ></button>
                  {showColorPicker[index] && (
                    <div className="absolute z-10 mt-2 p-2 shadow-md rounded bg-white">
                      <CirclePicker
                        color={colors[index]}
                        onChangeComplete={(color) =>
                          handleColorChange(color, index)
                        }
                        colors={colorPalette}
                        width="180px"
                      />
                      <button
                        className="mt-2 text-xs text-blue-500"
                        onClick={() => setShowAddColorPicker(true)}
                      >
                        Add Color
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className={`text-gray-700 font-medium ${
                    modelRef.name.length > 20
                      ? "text-xs"
                      : modelRef.name.length > 10
                      ? "text-sm"
                      : "text-base"
                  }`}
                >
                  {modelRef.name}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                {visibility[index] ? (
                  <VisibilityOutlinedIcon
                    className="text-gray-600 cursor-pointer"
                    onClick={() => handleVisibilityChange(index)}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    className="text-gray-300 cursor-pointer"
                    onClick={() => handleVisibilityChange(index)}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <span className="mr-2">Opacity:</span>
              <Slider
                min={0}
                max={1}
                step={0.1}
                value={opacity[index]}
                onChange={(value) => handleOpacityChange(index, value)}
                className="w-auto"
              />
            </div>
          </li>
        ))}
      </ul>
      {showAddColorPicker && (
        <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <ChromePicker color={newColor} onChange={handleNewColorChange} />
            <div className="flex justify-between mt-2">
              <button className="text-xs text-blue-500" onClick={addNewColor}>
                Add
              </button>
              <button
                className="text-xs text-red-500"
                onClick={() => setShowAddColorPicker(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelsSidebar;
