import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUid: null,
    currentUser: null,
    isFetching: false,
    isFetched: false,
    isSignedIn: false,
    error: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.isSignedIn = true;
      state.currentUid = action.payload;
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      state.isFetched = true;
    },
    signOutSuccess: (state) => {
      state.currentUid = null;
      state.currentUser = null;
      state.isSignedIn = false;
      state.isFetched = false;
    },
    updateModelInfos: (state, action) => {
      const { caseId, modelInfos } = action.payload;
      const specificCase = state.currentUser.cases[caseId];
      if (specificCase) {
        if (!specificCase.items) {
          specificCase.items = {};
        }
        specificCase.items.modelInfos = modelInfos;
      }
    },
  },
});

export const {
  loginFailure,
  loginSuccess,
  loginStart,
  setCurrentUser,
  signOutSuccess,
  updateModelInfos,
} = userSlice.actions;
export default userSlice.reducer;
