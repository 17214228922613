import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import Signup from "./pages/SignUp";
import Login from "./pages/Login";
import ThreeDModel from "./pages/ThreeDModel";
import CaseList from "./pages/CaseList";
import CasesPage from "./pages/CasesPage";
import ProfilePage from "./pages/ProfilePage";
import AddCasePage from "./pages/AddCasePage";


import Footer from "./components/Footer";

function App() {
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <div className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={isSignedIn ? <CaseList /> : <Navigate to="/login" />}
            />
            <Route
              path="/home"
              element={isSignedIn ? <CaseList /> : <Navigate to="/login" />}
            />
            <Route
              path="/signup"
              element={isSignedIn ? <Navigate to="/caselist" /> : <Signup />}
            />
            <Route
              path="/login"
              element={isSignedIn ? <Navigate to="/caselist" /> : <Login />}
            />
            <Route path="/case/:id" element={<CasesPage />} />
            <Route
              path="/caselist"
              element={isSignedIn ? <CaseList /> : <Navigate to="/login" />}
            />
            <Route
              path="/profilepage"
              element={isSignedIn ? <ProfilePage /> : <Navigate to="/login" />}
            />
            <Route
              path="/addcasepage"
              element={isSignedIn ? <AddCasePage /> : <Navigate to="/login" />}
            />
            <Route path="/model/:id" element={<ThreeDModel />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

function NotFound() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          404 NOT FOUND!
        </h1>
        <p className="text-gray-600">
          Sorry, the page you are looking for could not be found.
        </p>
      </div>
    </div>
  );
}

export default App;
