import Navbar from "../components/Navbar";
import SignUpForm from "../components/SignUpForm";

const SignUp = () => {
  return (
    <>
      <Navbar />
      <SignUpForm />
    </>
  );
};

export default SignUp;
