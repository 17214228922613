import SquareFootIcon from "@mui/icons-material/SquareFoot";
import LabelIcon from "@mui/icons-material/Label";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

const Tabs = ({ activeTab, onTabClick }) => {
  return (
    <div className="absolute right-0 top-0 w-64 h-full bg-gray-100 overflow-y-auto shadow-lg">
      <button
        onClick={() => onTabClick("models")}
        className={`py-2 px-4 ${
          activeTab === "models"
            ? "bg-blue-500 text-white"
            : "bg-white text-blue-500"
        }`}
      >
        <ViewInArIcon />
      </button>
      <button
        onClick={() => onTabClick("annotations")}
        className={`py-2 px-4 ${
          activeTab === "annotations"
            ? "bg-blue-500 text-white"
            : "bg-white text-blue-500"
        }`}
      >
        <LabelIcon />
      </button>
      <button
        onClick={() => onTabClick("measurements")}
        className={`py-2 px-4 ${
          activeTab === "measurements"
            ? "bg-blue-500 text-white"
            : "bg-white text-blue-500"
        }`}
      >
        <SquareFootIcon />
      </button>
    </div>
  );
};

export default Tabs;
