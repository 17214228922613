import Navbar from "../components/Navbar";
import CasesPageView from "../components/CasesPageView";

const CasesPage = () => {
  return (
    <>
      <Navbar />
      <CasesPageView />
    </>
  );
};

export default CasesPage;
